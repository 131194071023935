export function clean_email(email: string): TEmail {
  const normalized = email.trim().toLowerCase();

  const valid = normalized.match(/[^@]+@[^@]+\.[^@]+/);

  if (!valid) {
    throw new Error(`Invalid email: ${email}`);
  }

  return normalized as TEmail;
}

export function format_phone(value: string) {
  return value
    .replace('+1', '')
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export function capitalize(text: string): string {
  const [head, ...tail] = text;

  return `${head.toUpperCase()}${tail.join('')}`;
}
